import React, { Fragment, useState, useEffect } from "react"
import { Modal } from "react-responsive-modal"
import { 
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,
  scrollTo, 
  parseDate,
  parseTime
} from "../utils.js"

import importantIcon from "../assets/img/importante_icon.png"

const Comments = ({ token, applicant, comments, id, getHistorial, user }) => {
  const [ comment, setComment ] = useState('')
  const [ commentForDeleting, setCommentForDeleting ] = useState()
  const [ isJefeProductoSeleccion, setIsJefeProductoSeleccion ] = useState(false)
  const [ users, setUsers ] = useState([])

  useEffect(() => {
    if(token)
      getUsers()
  }, [token])

  useEffect(() => {
    if(user && user.metadata && users.length > 0){
      const jefeProdSel = users.find(u => u.type === 'JefeProductoSeleccion')
      if(user.metadata.email === jefeProdSel.email){
        setIsJefeProductoSeleccion(true)
      }

      const adminCopecGroup = 'GROUP#' + process.env.REACT_APP_ADMINISTRACION_COPEC_GROUP_ID
      if(user.groups.includes(adminCopecGroup)){
        setIsJefeProductoSeleccion(true)
      }
    }
  }, [user, users])

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setUsers(result.Items)
      },
      (error) => {
        alert(error)
      }
    )
  }

  async function uploadComment(){
    displayLoader()
    const data = {
      id: id,
      comment: comment
    }

    await fetch(process.env.REACT_APP_BASE_URL + "/workflow/comments",
    {
      method: 'POST',    
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getHistorial()
      setComment('')
      },
      (error) => {
        alert(error);
      }
    )
    hideLoader()
  }

  function closeModal(){
    setCommentForDeleting()
    hideGreyBackground()
  }

  function deleteCommentAlert(c){
    setCommentForDeleting(c)
    showGreyBackground()
    scrollTo(0, 100)
  }

  async function deleteComment(){
    const id = commentForDeleting.id
    displayLoader()

    await fetch(process.env.REACT_APP_BASE_URL + "/workflow/comments?id=" + id,
    {
      method: 'DELETE',    
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    })
    .then((result) => {
      getHistorial()
      closeModal()
      },
      (error) => {
        alert(error);
      }
    )
    hideLoader()
  }


  return (
    <div className='comments'>
      <div className="comments-title">OBSERVACIONES</div>
      <textarea value={comment} rows="4" maxLength="500" onChange={(e) => setComment(e.target.value)} />
      <div className="textarea-counter">{comment? comment.length: 0}/500</div>
      <button className="custom-button blue-button comment-button" onClick={uploadComment}>Comentar</button>
      <div className="comments-container">
        <div className="comments-container__title">OBSERVACIONES RECIENTES</div>
        {
          comments.map((comment, index) => {
            return(<div key={index} className="comments-container__comment">
              <div className='comments-container__name'>{comment.name}</div>
              <div className="comments-container__text">{comment.data}</div>
              <div className="comments-container__date">{parseDate(comment.date)}</div>
              <div className="comments-container__time">{parseTime(comment.date)}</div>
              {isJefeProductoSeleccion &&
                <button 
                  className="delete-comment-button custom-button red-button-hollow" 
                  onClick={() => deleteCommentAlert(comment)}>
                  Borrar Comentario
                </button>
              }
            </div>)
          })
        }
      </div>
      <Modal
        open={commentForDeleting}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeModal}
      >
        <div className="modal-rechazo-container">
          <img className="big-icon" src={importantIcon} />
          <div className="modal-title">
            Se va a borrar el comentario
          </div>
          <div className="modal-info">
            {`"${commentForDeleting && commentForDeleting.data}"`} 
            <br />
            ¿Está seguro que desea continuar?
          </div>
          <hr />
          <button className="save-button rechazo-button rechazo-button-hollow" onClick={closeModal}>Cancelar</button>
          <button className="save-button rechazo-button" onClick={deleteComment}>Aceptar</button>
        </div>
      </Modal>
    </div>
  )
};

export default Comments;
