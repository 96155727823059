import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker"
import { Modal } from "react-responsive-modal"
import alerta_icon from "../assets/img/importante_icon.png"
import cross_icon from "../assets/img/cross-icon.svg"
import { 
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,
  scrollTo
} from "../utils.js";

import '../styles/users.scss'

const userActions = {
  deleted: "Borrar",
  active: "Activar",
  paused: "Pausar"
}

const UsersTable = ({
  users, 
  getUsers, 
  token, 
  showZona = false, 
  showSede = false,
  showStatus = false,
}) => {
  const [selectedUser, setSelectedUser] = useState()
  const [selectedUserName, setSelectedUserName] = useState()
  const [showUserAlert, setShowUserAlert] = useState(false)
  const [changeType, setChangeType] = useState("")
  const [userChange, setUserChange] = useState({})

  async function saveUser(){
    const data = {
      id: selectedUser.id,
      name: selectedUser.name,
      rut: selectedUser.rut,
      email: selectedUser.email
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    
    await fetch(url,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getUsers()
      setSelectedUserName()
      setSelectedUser()
      hideGreyBackground()
      },
      (error) => {
        alert(error);
      }
    )
  }

  async function saveUserStatus(){
    const data = {
      id: userChange.id,
      status: changeType
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    
    await fetch(url,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getUsers()
      setChangeType("")
      setUserChange({})
      hideGreyBackground()
      setShowUserAlert(false)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function editUser(user){
    setSelectedUser(user)
    setSelectedUserName(user.name)
    showGreyBackground()
  }

  function changeStatus(newStatus, user){
    setUserChange(user)
    setChangeType(newStatus)
    setShowUserAlert(true)
    showGreyBackground()
    scrollTo(0, 100)
  }

  function closeModal(){
    setSelectedUser()
    hideGreyBackground()
  }

  function getUserStatus(status){
    if(status === "active" || status === undefined)
      return "Activo"
    
    return "En pausa"
  }

  function closeUserAlert(){
    setUserChange({})
    setChangeType("")
    setShowUserAlert(false)
    hideGreyBackground()
  }

  return (
    <>
      <table className="copec-table-base processes-table users-admin-table">
        <tr>
          <th className="first th-300">
            <div className="th-data">
              Nombre
            </div>
          </th>
          <th className="first th-120">
            <div className="th-data">
              RUT
            </div>
          </th>
          <th className="first th-150">
            <div className="th-data">
              Mail
            </div>
          </th>
          { showZona &&
            <th className="first th-120">
              <div className="th-data">
                Zona
              </div>
            </th>
          }
          { showSede &&
            <th className="first th-120">
              <div className="th-data">
                Sede
              </div>
            </th>
          }
          { showStatus &&
            <th className="first th-120">
              <div className="th-data">
                Estado
              </div>
            </th>
          }
          <th className="first th-120">
            <div className="th-data">
              Acciones
            </div>
          </th>
        </tr>
        <tr className="table-separator"><td colSpan="4"></td></tr>
        <tbody>
          {
            users.map((user) => {
              return(
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.rut}</td>
                  <td>{user.email}</td>
                  { showZona &&
                    <td>{user.zone}</td>
                  }
                  { showSede &&
                    <td>{user.sede}</td>
                  }
                  { showStatus &&
                    <td>{getUserStatus(user.status)}</td>
                  }
                  <td>
                    <span className="show-pointer" onClick={() => editUser(user)}>
                      Editar
                    </span>
                    {showStatus &&
                      <>
                        &nbsp;&nbsp;&nbsp;                    
                        <span className="show-pointer" onClick={() => changeStatus("paused", user)}>
                          Pausar
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span className="show-pointer" onClick={() => changeStatus("deleted", user)}>
                          Borrar
                        </span>
                      </>
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <Modal
        open={selectedUser}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeModal}
      >
      { selectedUser &&
        <div className="administracion-usuarios-form">
          <div className="react-responsive-modal-title">
            Editando usuario {selectedUserName}
          </div>
          <div className="administracion-usuarios-form-label">NOMBRE</div>
          <input type="text" value={selectedUser.name} onChange={(e) => setSelectedUser({...selectedUser, name: e.target.value})}/>
          <div className="administracion-usuarios-form-label">RUT</div>
          <input type="text" value={selectedUser.rut} onChange={(e) => setSelectedUser({...selectedUser, rut: e.target.value})}/>
          <div className="administracion-usuarios-form-label">EMAIL</div>
          <input type="text" value={selectedUser.email} onChange={(e) => setSelectedUser({...selectedUser, email: e.target.value})}/>
        </div>
      }
      <div className="button-container-full-width">
        <button className="action-button hollow" onClick={closeModal}>
          Cancelar
        </button>
      </div>
      <div className="button-container-full-width">
        <button className="action-button" onClick={saveUser}>Guardar</button>
      </div>
      </Modal>

      <Modal
        open={showUserAlert}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeUserAlert}
      >
        <div className="modal-rechazo-container">
          <img className="big-icon" src={alerta_icon} />
          <div className="modal-title">
            Se va a {userActions[changeType]} el usuario {userChange.name}
          </div>
          <div className="modal-info">
            ¿Está seguro que desea continuar?
          </div>
          <hr />
          <button className="save-button rechazo-button rechazo-button-hollow" onClick={closeUserAlert}>Cancelar</button>
          <button className="save-button rechazo-button" onClick={saveUserStatus}>Aceptar</button>
        </div>
      </Modal>


    </>
  )
};

export default UsersTable;