import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker"
import { Modal } from "react-responsive-modal"
import UsersTable from "./UsersTable"

import { 
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,  
  parseDate,
  parentRedirect,
  customStylesSelect,
  SingleOption,
  orderArray,
  scrollTo
} from "../utils.js";

import { etapasDictionary, userTypesDictionary } from '../dictionary.js'
import { zones } from '../constants.js'
import Select from 'react-select'
import '../styles/users.scss'

const Users = ({token}) => {
  const [jefesZonaUsers, setJefesZonaUsers] = useState([])
  const [gerenteEdsUsers, setGerenteEdsUsers] = useState([])
  const [gestoresUsers, setGestoresUsers] = useState([])
  const [gerentesUsers, setGerentesUsers] = useState([])
  const [jefeComercialUsers, setJefeComercialUsers] = useState([])
  const [gerenteComercialUsers, setGerenteComercialUsers] = useState([])
  const [jefeProductoSeleccionUsers, setJefeProductoSeleccionUsers] = useState([]) 
  const [jefeProductoFormacionUsers, setJefeProductoFormacionUsers] = useState([]) 
  const [selectedUser, setSelectedUser] = useState()
  const [selectedUserName, setSelectedUserName] = useState()
  const [locations, setLocations] = useState([])
  const [newUser, setNewUser] = useState()
  const [jefesComercialesOptions, setJefesComercialesOptions] = useState([])
  const [gerentesOptions, setGerentesOptions] = useState([])
  const [locationsOptions, setLocationsOptions] = useState([])

  useEffect(() => {
    if(token){
      getUsers()
      getLocations()
    }
  }, [token]);

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        classifyUsers(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function getLocations(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/reports/zones'
    fetch(url)
      .then(res => res.json())
      .then((result) => {
        setLocations(result)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function classifyUsers(users){
    const jefeszona = []
    const gerenteEds = []
    const gestores = []
    const gerentes = []
    const jefeComercial = []
    const gerenteComercial = []
    const jefeProductoSeleccion = []
    const jefeProductoFormacion = []

    users.forEach((user) => {
      if(user.type === 'JefeZona')
        jefeszona.push(user)
      else if(user.type === 'GerenteEDS')
        gerenteEds.push(user)
      else if(user.type === 'Gestor')
        gestores.push(user)
      else if(user.type === 'Gerente')
        gerentes.push(user)
      else if(user.type === 'JefeComercial')
        jefeComercial.push(user)
      else if(user.type === 'GerenteComercial')
        gerenteComercial.push(user)
      else if(user.type === 'JefeProductoSeleccion')
        jefeProductoSeleccion.push(user)
      else if(user.type === 'JefeProductoFormacion')
        jefeProductoFormacion.push(user)
    })

    setJefesZonaUsers(jefeszona)
    setGerenteEdsUsers(gerenteEds)
    setGestoresUsers(gestores)
    setGerentesUsers(gerentes)
    setJefeComercialUsers(jefeComercial)
    setGerenteComercialUsers(gerenteComercial)
    setJefeProductoSeleccionUsers(jefeProductoSeleccion)
    setJefeProductoFormacionUsers(jefeProductoFormacion)
  }

  function editUser(user){
    setSelectedUser(user)
    setSelectedUserName(user.name)
    scrollTo(0, 100)
    showGreyBackground()
  }

  function addUser(type){
    const emptyUser = {
      type: type,
      name: "",
      rut: "",
      email: "",
      zone: "",
      sede: "",
      id_gerente: "",
      id_jefe_comercial: ""
    }
    setNewUser(emptyUser)
    scrollTo(0, 100)
    showGreyBackground()
  }

  async function createUser(){
    const data = {
      type: newUser.type,
      name: newUser.name,
      rut: newUser.rut,
      email: newUser.email
    }

    if(newUser.zone)
      data.zone = newUser.zone.value

    if(newUser.sede)
      data.sede = newUser.sede.value

    if(newUser.gerente){
      data.id_gerente = newUser.gerente.id
      data.gerente = newUser.gerente.name
    }

    if(newUser.jefeComercial){
      data.id_gerente = newUser.jefeComercial.id_gerente
      data.id_jefe_comercial = newUser.jefeComercial.id
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    
    await fetch(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getUsers()
      setSelectedUserName()
      setSelectedUser()
      setNewUser()
      hideGreyBackground()
      },
      (error) => {
        alert(error);
      }
    )
  }

  function closeModal(){
    setSelectedUser()
    setNewUser()
    hideGreyBackground()
  }

  function zonaChange(z){
    const newUserChanged = {...newUser}
    newUserChanged.zone = z
    newUserChanged.location = null
    newUserChanged.jefeComercial = null
    newUserChanged.gerente = null
    setNewUser(newUserChanged)
  }

  useEffect(() => {
    getJefesComerciales()
    getGerentes()
    getFilteredLocations()
  }, [newUser])

  function getJefesComerciales(){
    if(!newUser || !newUser.zone){
      setJefesComercialesOptions([])
      return
    }
    const filteredJefesComerciales = jefeComercialUsers.filter(j => j.zone === newUser.zone.value)
    const newJefesComercialesArr = []
    filteredJefesComerciales.forEach(function(j){
      j.label = j.name
      newJefesComercialesArr.push(j)
    })
    setJefesComercialesOptions(newJefesComercialesArr)
  }

  function getGerentes(){
    if(!newUser || !newUser.zone){
      setGerentesOptions([])
      return
    }
    const filteredGerentes = gerentesUsers.filter(j => j.zone === newUser.zone.value)
    const newGerentesArr = []
    filteredGerentes.forEach(function(j){
      j.label = j.name
      newGerentesArr.push(j)
    })
    setGerentesOptions(newGerentesArr)
  }

  function getFilteredLocations(){
    if(!newUser || !newUser.zone){
      setLocationsOptions([])
      return
    }

    const filteredLocations = locations.filter(l => l.zona === newUser.zone.value)
    const newLocationsArr = []
    filteredLocations.forEach(function(j){
      newLocationsArr.push({label: j.localidad, value: j.localidad})
    })
    setLocationsOptions(newLocationsArr)
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Administración de Usuarios</div>
        <hr className="title-hr" />
      </div>

      <div className="usuarios-section-title">Gerente Comercial</div>
      <UsersTable
        users={gerenteComercialUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Gerente EDS</div>
      <UsersTable
        users={gerenteEdsUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Jefe Producto Selección</div>
      <UsersTable
        users={jefeProductoSeleccionUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Jefe Formación Concesionarios</div>
      <UsersTable
        users={jefeProductoFormacionUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Gestores Postulación</div>
      <UsersTable
        users={gestoresUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
      />

      <div className="usuarios-section-title">
        <span>Gerentes de Oficina</span>
        <button className="user-create-button custom-button red-button" onClick={() => addUser('Gerente')}>Crear</button>
      </div>
      <UsersTable
        users={gerentesUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
        showStatus={true}
      />

      <div className="usuarios-section-title">
        <span>Jefes Comerciales</span>
        <button className="user-create-button custom-button red-button" onClick={() => addUser('JefeComercial')}>Crear</button>
      </div>
      <UsersTable
        users={jefeComercialUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
        showStatus={true}
      />

      <div className="usuarios-section-title">
        <span>Jefes de Zona</span>
        <button className="user-create-button custom-button red-button" onClick={() => addUser('JefeZona')}>Crear</button>
      </div>
      <UsersTable
        users={jefesZonaUsers}
        getUsers={getUsers}
        token={token}
        showSede={true}
        showZona={true}
        showStatus={true}
      />

      <Modal
        open={newUser}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeModal}
      >
      { newUser &&
        <div className="administracion-usuarios-form">
          <div className="react-responsive-modal-title">
            Crear usuario {userTypesDictionary[newUser.type]}
          </div>
          <div className="administracion-usuarios-form-label">NOMBRE</div>
          <input type="text" value={newUser.name} onChange={(e) => setNewUser({...newUser, name: e.target.value})}/>
          <div className="administracion-usuarios-form-label">RUT</div>
          <input type="text" value={newUser.rut} onChange={(e) => setNewUser({...newUser, rut: e.target.value})}/>
          <div className="administracion-usuarios-form-label">EMAIL</div>
          <input type="text" value={newUser.email} onChange={(e) => setNewUser({...newUser, email: e.target.value})}/>

          { (newUser.type === "JefeZona" || newUser.type === "Gerente" || newUser.type === "JefeComercial") &&
            <>
              <div className="administracion-usuarios-form-label">ZONA</div>
              <div className="filter-input">
                <Select
                  className='filter-select'
                  value={newUser.zone}
                  onChange={(e) => zonaChange(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={zones}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  isSearchable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesSelect}
                />
              </div>
            </>
          }
          { newUser.type === "JefeZona" &&
            <>
              <div className="administracion-usuarios-form-label">SEDE</div>
              <div className="filter-input">
                <Select
                  className='filter-select'
                  value={newUser.sede}
                  onChange={(e) => setNewUser({...newUser, sede: e})}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={locationsOptions}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  isSearchable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesSelect}
                />
              </div>
            </>
          }

          { newUser.type === "JefeComercial" &&
            <>
              <div className="administracion-usuarios-form-label">GERENTE</div>
              <div className="filter-input">
                <Select
                  className='filter-select'
                  value={newUser.gerente}
                  onChange={(e) => setNewUser({...newUser, gerente: e})}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={gerentesOptions}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  isSearchable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesSelect}
                />
              </div>
            </>
          }

          { newUser.type === "JefeZona" &&
            <>
              <div className="administracion-usuarios-form-label">JEFE COMERCIAL</div>
              <div className="filter-input">
                <Select
                  className='filter-select'
                  value={newUser.jefeComercial}
                  onChange={(e) => setNewUser({...newUser, jefeComercial: e})}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={jefesComercialesOptions}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  isSearchable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesSelect}
                />
              </div>
            </>
          }
        </div>
      }
      <div className="button-container-full-width">
        <button className="action-button hollow" onClick={closeModal}>
          Cancelar
        </button>
      </div>
      <div className="button-container-full-width">
        <button className="action-button" onClick={createUser}>Crear</button>
      </div>
      </Modal>
    </div>
  )
};

export default Users;