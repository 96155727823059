import React, { Fragment, useState, useEffect } from "react";
import {
  getPresignedUrlForGet,
  displayLoader,
  hideLoader,
  dynamicSort,
  parentRedirect,
  parseDate,
  getLocaleStringNum,
  validateEmail
} from "../utils.js";
import useDynamicRefs from 'use-dynamic-refs'
import Step from './Step'
import Historial from './Historial'
import Expediente from './Expediente'
import history from "../history"
import '../styles/applicant.scss'
import editarIcon from "../assets/img/editar.svg"

const initial_combustibles = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
const initial_socios = { nombre: '', rut: '', profesion: '', porcentaje: '' }

const REVISION_NOMBRAMIENTO_STEP = 11

const Applicant = (props) => {
  const [ token, setToken ] = useState(null)
  const [ applicant, setApplicant ] = useState({})
  const [ historial, setHistorial ] = useState([])
  const [ comments, setComments ] = useState([])
  const [ fileNames, setFileNames ] = useState({})
  const [ stepRequirements, setStepRequirements ] = useState({})
  const [ fileInputs, setFileInputs ] = useState({})
  const [ multipleFileInputs, setMultipleFileInputs ] = useState({})
  const [ textInputs, setTextInputs ] = useState({})
  const [ dateInputs, setDateInputs ] = useState({})
  const [ step, setStep ] = useState()
  const [ disableChanges, setDisableChanges ] = useState(false)
  const [ tableCombustiblesInputs, setTableCombustiblesInputs ] = useState({
      "Gas93": Object.assign({}, initial_combustibles), 
      "Gas95": Object.assign({}, initial_combustibles),
      "Gas97": Object.assign({}, initial_combustibles),
      "Diesel": Object.assign({}, initial_combustibles),
      "Kerosene": Object.assign({}, initial_combustibles),
      "Total": Object.assign({}, initial_combustibles)
    })
  const [ tableCapitalTrabajoInputs, setTableCapitalTrabajoInputs ] = useState({
      "Capital de Inversión": 0
    })
  const [ tableSociosInputs, setTableSociosInputs ] = useState([
      Object.assign({}, initial_socios), 
      Object.assign({}, initial_socios),
      Object.assign({}, initial_socios),
      Object.assign({}, initial_socios),
      Object.assign({}, initial_socios)
    ])

  const [ inputs, setInputs ] = useState([])
  const [ extras, setExtras ] = useState([])
  const [ comment, setComment ] = useState('')
  const [ content, setContent ] =  useState('step')
  const [ enabledSaveButton, setEnabledSaveButton ] = useState(false)
  const [ newEmail, setNewEmail ] = useState('')
  const [ newPhone, setNewPhone ] = useState('')
  const [ isOpenSidebarForm, setIsOpenSidebarForm ] = useState(false)

  const id = props.match.params.id
  const user = props.user

  useEffect(() => {
    if(props.token){
      setToken(props.token)
    }
  }, [props.token]);

  useEffect(() => {
    if(token){
      getProcess()
      getHistorial()
    }
  }, [token]);

  function goBack(){
    parentRedirect('/c-postulantes')
  }

  async function getProcess(paramStep = null){
    displayLoader()
    let url = process.env.REACT_APP_BASE_URL + '/workflow/processes?id=' + id
    if(paramStep)
      url += '&step=' + paramStep
    await fetch(url, { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        hideLoader()
        setApplicantFunc(result.Item)
        if(!paramStep)
          setStep(result.Item.step)
      },
      (error) => {
        alert(error);
        hideLoader()
      }
    )
  }

  function setApplicantFunc(result){
    if(result.CuadroCapitalTrabajo){
      setTableCapitalTrabajoInputs(result.CuadroCapitalTrabajo)
      result["CuadroCapitalTrabajo"] = []
    }
    if(result.CuadroVentasCombustiblesLubricantes){
      setTableCombustiblesInputs(result.CuadroVentasCombustiblesLubricantes)
      result["CuadroVentasCombustiblesLubricantes"] = []
    }
    if(result.TableSocios){
      setTableSociosInputs(result.TableSocios)
      result["TableSocios"] = []
    }

    setApplicant(result)
    const stepRequirements = result.stepRequirements

    if(result.stepRequirements){
      setStepRequirements(result.stepRequirements)
      setInputs(result.stepRequirements["Inputs"] || [])
      setExtras(result.stepRequirements["Extras"] || [])
    }
    else{
      setStepRequirements({})
      setInputs([])
      setExtras([])
    }

    if(result.checking_workflow_status){
      setTimeout(function(){ 
        getProcess() 
      }, 6000);
    }
  }

  async function downloadFile(downloadName){
    displayLoader()
    const url = await getPresignedUrlForGet(downloadName, token)
    window.open(url[0], "_blank")
    hideLoader()
  }

  async function getHistorial(){
    displayLoader()
    let url = process.env.REACT_APP_BASE_URL + '/workflow/history?id=' + id
    
    await fetch(url,
    {
      method: 'GET',
      headers: { token: token }
    })
    .then(res => res.json())
    .then((result) => {
      const newComments = []
      const newHistorial = []

      result.Items.sort(dynamicSort("date"))      
      result.Items.forEach(function(item){
        if(item.type === 'comment'){
          newComments.push(item)
        }
        else{
          newHistorial.push(item)
        }
      })

      setComments(newComments.reverse())
      setHistorial(newHistorial)

      },
      (error) => {
        alert(error);
      }
    )
    hideLoader()
  }

  function goPreviousStep(){
    setEnabledSaveButton(false)
    if(step - 1 < applicant.step - 1)
      setDisableChanges(true)
    getProcess(step - 1)
    setStep(step - 1)
  }

  function goNextStep(){
    setEnabledSaveButton(false)
    if(step + 1 >= applicant.step - 1)
      setDisableChanges(false)
    if(step + 1 === applicant.step)
      getProcess()
    else
      getProcess(step + 1)

    setStep(step + 1)
  }

  function openSidebarForm(){
    setNewEmail(applicant.email)
    setNewPhone(applicant.phone)
    setIsOpenSidebarForm(true)
  }

  function saveSidebarChanges(){
    if(!validateEmail(newEmail)){
      alert("Error. Email inválido.")
      return
    }

    if(newPhone.length < 9){
      alert("Error. Teléfono inválido.")
      return
    }

    displayLoader()
    const data = {
      id: applicant.id,
      phone: newPhone,
      email: newEmail
    }

    fetch(process.env.REACT_APP_BASE_URL + "/workflow/processes",
    {
      method: 'PUT',    
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      setIsOpenSidebarForm(false)
      getProcess()
      },
      (error) => {
        hideLoader()
        alert(error)
      }
    )
  }

  return (
    <div className='applicant'>
      <div className="top-info">
        <button className="back-button" onClick={goBack}>&#8592;&nbsp;Volver</button>
        <div className="view-title"><b>Postulación concesionarios | </b>Flujo de selección</div>
        <hr className="title-hr" />
      </div>
      <div className='applicant-columns'>
        <div className='col-left'>
          <img className='applicant-photo' src={applicant.photo} />
          <br />
          {
            applicant.isConcesionarioChange &&
            <div className='concesionario-change-title'>CAMBIO DE CONCESIONARIO</div>
          }
          <div className='data-title'>Nombres</div>
          <div className='data-info'>{ applicant.name }</div>

          <div className='data-title'>Apellidos</div>
          <div className='data-info'>{ applicant.lastName }</div>

          <div className='data-title'>RUT</div>
          <div className='data-info'>{ applicant.rut }</div>

          <div className='data-title'>Fecha de Nacimiento</div>
          <div className='data-info'>{ applicant.bornDate }</div>

          <div className='data-title'>E-mail</div>
          {!isOpenSidebarForm &&
            <div className='data-info'>
              <div className="data-info-email">
                { applicant.email }
              </div>
              <img className="editar-icon" src={editarIcon} onClick={openSidebarForm} />
            </div>
          }
          {isOpenSidebarForm &&
            <input className="sidebar-text-input" type="text" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
          }

          <div className='data-title'>Profesión</div>
          <div className='data-info'>{ applicant.profession }</div>

          <div className='data-title'>Teléfono</div>
          {!isOpenSidebarForm &&
            <div className='data-info'>
              { applicant.phone }
              <img className="editar-icon" src={editarIcon} onClick={openSidebarForm} />
            </div>
          }
          {isOpenSidebarForm &&
            <input className="sidebar-text-input" type="text" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
          }

          <div className='data-title'>Lugar de Residencia</div>
          {
            applicant.residencia && applicant.residencia.map((r, key) => 
              <div className={`data-info data-info-residencia ${key + 1 === applicant.residencia.length? 'data-info-residencia-last': ''}`}>{ r }</div>
            )
          }

          <div className='data-title'>Ultimo trabajo</div>
          <div className='data-info'>{ applicant.lastJob }</div>

          <div className='data-title'>Disponibilidad Inmediata</div>
          <div className='data-info'>{ applicant.immediateAvailability }</div>

          <div className='data-title'>Localidad</div>
          <div className='data-info'>{ applicant.location }</div>

          { applicant.referred === "si" &&
            <>
              <div className='data-title'>Referido por</div>
              <div className='data-info'>{ applicant.referredName }</div>
            </>
          }

          {
            applicant.cv && 
            <button className='download-file-button' onClick={() => downloadFile(applicant.cv)}>Curriculum Vitae</button>
          }
          {isOpenSidebarForm &&
            <div className="sidebar-actions-container">
              <button className="custom-button red-button" onClick={() => setIsOpenSidebarForm(false)}>Cancelar</button>
              <button className="custom-button blue-button" onClick={saveSidebarChanges}>Guardar</button>
            </div>
          }

        </div>
        <div className='col-right'>
          <div className='select-content'>
            <button className={content === 'step' ? 'selected' : ''} onClick={() => setContent('step')}>PASO ACTUAL</button>
            <button className={content === 'expediente' ? 'selected' : ''} onClick={() => setContent('expediente')}>EXPEDIENTE</button>
            <button className={content === 'historial' ? 'selected' : ''} onClick={() => setContent('historial')}>HISTORIAL</button>
            <hr className={`selected-tab selected-tab-${content}`} />
          </div>
          {
            content === 'step' && applicant.endDate === 0 &&
            <Step
              token = { token }
              applicant = { applicant }
              historial = { historial }
              comments = { comments } 
              id = { id }
              inputs = { inputs }
              extras = { extras }
              textInputs = { textInputs }
              fileInputs = { fileInputs }
              multipleFileInputs = { multipleFileInputs }
              dateInputs = { dateInputs }
              tableCombustiblesInputs = { tableCombustiblesInputs }
              tableCapitalTrabajoInputs = { tableCapitalTrabajoInputs }
              tableSociosInputs = { tableSociosInputs }
              setFileInputs = { setFileInputs }
              setMultipleFileInputs = { setMultipleFileInputs }
              setTextInputs = { setTextInputs }
              setDateInputs = { setDateInputs }
              setTableCombustiblesInputs = { setTableCombustiblesInputs }
              setTableCapitalTrabajoInputs = { setTableCapitalTrabajoInputs }
              setTableSociosInputs = { setTableSociosInputs }
              getHistorial = { getHistorial }
              getProcess = { getProcess }
              downloadFile = { downloadFile }
              step={step}
              disableChanges={disableChanges}
              goPreviousStep={goPreviousStep}
              goNextStep={goNextStep}
              enabledSaveButton={enabledSaveButton}
              setEnabledSaveButton={setEnabledSaveButton}
              user={user}
            />
          }
          {
            content === 'step' && Object.keys(applicant).length > 0 && applicant.endDate !== 0 &&
            <div className="ended-process">Proceso Terminado el { parseDate(applicant.endDate) }</div>
          }
          {
            content === 'historial' &&
            <Historial 
              applicant = { applicant }
              historial = { historial }
            />
          }
          {
            content === 'expediente' &&
            <Expediente 
              token = { token }
              applicant = { applicant }
              downloadFile = { downloadFile }
              historial = { historial }
            />
          }
        </div>
      </div>
    </div>
  )
};

export default Applicant;
