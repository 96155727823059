export const dictionary = {
	//Nombres de Pasos
	InitialRevision: "Revisión inicial de solicitudes",
	EntrevistaJefeZona: "Entrevista Jefe de Zona",
	EntrevistaPsicologica: "Entrevista Psicológica",
	EntrevistaJefeComercial: "Entrevista Jefe Comercial",
	EntrevistaGerenteOficina: "Entrevista Gerente de Oficina",
	CursoInduccion: "Curso de Inducción",
	RevisionInformeCopecCentro: "Revision Informe Curso Inducción",
	PracticaEds: "Práctica EDS",
	PruebaCertificacion: "Prueba de Certificación",
	RevisionDocumentacion: "Revisión Documentación",
	FirmaCartasCompromiso: "Firma Cartas de Compromiso",
	RevisionNombramiento: "Revisión Nombramiento",
	FirmaNombramientoGerenteOficinaComercial: "Firma Nombramiento Gerente Oficina Comercial",
	FirmaNombramientoGerenteEds: "Firma Nombramiento Gerente EDS",
	FirmaNombramientoGerenteComercial: "Firma Nombramiento Gerente Comercial",
	CierreNombramiento: "Cierre Nombramiento",
	EntregaEds: "Toma de EDS",

	//Nombres de inputs
	Next: "Siguiente",
	End: "Terminar",
	Rechazo: "Rechazo",

	//PASO 1
	PerfilRevisado: "Se reviso el perfil y a la espera de asignacion segun disponibilidad en la zona",

	//PASO 2
	FechaEntrevistaTelefonica: "Fecha",
	ComentariosEntrevistaTelefonica: "Comentarios",

	//PASO 3
	FechaEntrevistaPsicologica: "Fecha",
	ComentariosEntrevistaPsicologica: "Comentarios",
	InformePsicolaboral: "Informe Psicolaboral",

	//PASO 4
	FechaTestPsicologico: "Fecha",
	NotaPsicologico: "Nota Entrevista Psicológica",
	RecomendacionPsicologico: "Recomendación",
	InformePsicologico: "Informe Psicológico",

	//PASO 5
	FechaJefeZona: "Fecha",
	NotaJefeZona: "Nota",
	ExpectedIncome: "Ingresos Esperados",
	ComentarioJefeZona: "Comentarios",

	//PASO 6
	FechaJefeComercial: "Fecha",
	NotaJefeComercial: "Nota",
	ComentarioJefeComercial: "Comentarios",

	//PASO 7
	FechaGerenteOficina: "Fecha",
	NotaGerenteOficina: "Nota",
	ComentarioGerenteOficina: "Comentarios",

	//PASO 8
	FechaCursoInduccion: "Fecha Inicio",
	FechaFinCursoInduccion: "Fecha Fin",
	NotaCursoInduccion: "Nota",
	InformeCursoInduccion: "Informe curso",
	CartaRecomendacionCursoInduccion: "Carta de recomendación",
	CorreoCursoInduccion: "Correo enviado con acceso a material de prueba de certificación",
	FechaPrueba: "Fecha de la evaluación",
	NotaPrueba: "Porcentaje obtenido en la evaluación",
	CertificadoPrueba: "Certificado",	
	CorreoPorcentajeCursoInduccion: "Envio via mail del porcentaje obtenido y comentarios del curso a Copec Centro",

	//PASO 9
	DocFinanzas: "Informe comercial",
	MemoFinanzas: "Memo aprobación finanzas",
	DocHipoteca: "Constitución hipoteca",
	MemoHipoteca: "Memo hipoteca formalizada",	
	DocSociedad: "Constitución sociedad aprobada por As. Legal",
	MemoSociedad: "Memo constitución sociedad sin reparos",

	//PASO 10
	FirmaDocumentos: "Firma de Documentos",

	//PASO 11
	NacionalidadConcesionario: "Nacionalidad Postulante",
	ConcesionarioSaliente: "Nombre concesionario saliente",
	RutSociedad: "Rut Sociedad",
	TableSocios: "Integrantes de la Sociedad",
	PromedioVentasMesDesde: "Mes inicio promedio ventas ultimos 3 meses",
	PromedioVentasKerosene: "Promedio ventas Kerosene",
	PromedioVentasGasolina: "Promedio ventas Gasolina",
	PromedioVentasDiesel: "Promedio Ventas Diesel",
	PromedioVentasLubricantes: "Promedio Ventas Lubricantes",
	CapitalExplotacion: "Capital explotación",
	EquivalenteEnDiasVenta: "Equivalente en días de venta",
	CapitalInversion: "Capital para inversión",
	BienRaiz: "Descripción del bien raíz",
	DireccionBienRaiz: "Dirección bien raiz",
	CiudadBienRaiz: "Ciudad bien raiz",
	ValorBienRaiz: "Valor comercial (UF) bien raiz",
	ValorLiquidacionBienRaiz: "Valor de liquidación bien raiz",
	DireccionOtroBienRaiz: "Dirección otras garantías",
	CiudadOtroBienRaiz: "Ciudad otras garantías",
	ValorOtroBienRaiz: "Valor comercial (UF) otras garantías",
	ValorLiquidacionOtroBienRaiz: "Valor de liquidación otras garantías",
	NumHipotecas: "Cantidad de hipotecas",

	//PASO 12
	CartonNombramientoF1: "Carton de Nombramiento Firmado por Gerente Oficina",
	//PASO 13
	CartonNombramientoF2: "Carton de Nombramiento Firmado por Gerente",
	//PASO 14
	FechaPractica: "Fecha Inicio",
	FechaFinPractica: "Fecha Fin",
	NotaPractica: "Nota",
	EdsPractica: "EDS",
	TutorPractica: "Nombre Tutor",
	InformePractica: "Informe",
	InformeConcesionarioMonitor: "Informe Concesionario Monitor",

	//PASO 15
	//PASO 16
	FechaEntregaEds: "Fecha",




	//EXTRAS
	CuadroVentasCombustiblesLubricantes: "Completa el cuadro de compromiso de ventas de combustibles (m³)",
	MesInicioOperacion: "Mes de inicio",
	CuadroCapitalTrabajo: "Completa el cuadro de capital de trabajo (UF)",
	DireccionEds: "Dirección EDS",
	Eds: "EDS",
	PromedioMensualLubricantes: "Promedio mensual Lubricantes Mobil (LTS)",
	RazonSocial: "Razón social",	

	//Documentos transversales
	//Legal - Sociedad
	EscrituraSociedad: "Borrador Escritura Sociedad",
	ConstitucionSociedad: "Constitución sociedad aprobada por asesoría legal",

	//Legal - Hipoteca
	HipotecaFavorCopec: "BORRADOR hipoteca a favor de copec",
	TasacionPropiedadHipoteca: "Tasación de la propiedad hipotecada",
	CopiaDominioVigente: "Inscripción de dominio con certificado de vigencia",
	EscrituraCompraPropiedad: "Escritura compraventa",
	EscrituraCompraPropiedadAnteriores: "Escrituras publicas de compraventa/títulos traslaticios (hasta 10 años)",
	CertificadoContribuciones: "Certificado deuda contribuciones o avaluo para bienes excentos de contribuciones",
	Serviu: "Certificado no expropiación serviu",
	DireccionObrasMunicipales: "Certificado no expropiabilidad dirección de obras municipales",
	CertificadoHipotecaGravamenes: "Certificado de hipotecas y gravámenes",
	CertificadoMatrimonio: "Certificado de matrimonio",
	InscripcionSociedadRegistroComercio: "Inscripción sociedad registro de comercio",
	PoderesRepresentantesLegalesVigente: "Poderes de los representantes legales con vigencia",

	Archivo1: "Archivo obligatorio 1",
	Archivo2: "Archivo obligatorio 2",
	Archivo3: "Archivo obligatorio 3",

	// Tipos de rechazo
	no_vacancy: "No hay vacantes",
	does_not_meet_requirements: "No cumple requisitos",
	another_reason: "Otra razon"
}

export const errorsDictionary = {
	'workflow creation': 'No se creo correctamente el workflow. ',
	'uploadDocuments': 'Error al subir los documentos. ',
	'setSignableDocuments': 'Error al asignar los documentos para firma. ',
	'assignSigner': 'Error al asignar firmantes. ',
	'validateStep': 'Error al validar paso. ',
	'assignToSteps': 'Error al asignar usuario al paso. ',
	'carton': 'Carton de Nombramiento',
	'compromises': 'Cartas de compromiso',
	'fastSign': 'Firma Rapida',
	'hipoteca': 'Hipoteca',
	'sociedad': 'Sociedad'
}

export const etapasDictionary = {
	'Sel': 'Selección',
	'Cap': 'Centro de evaluación',
	'Nom': 'Nombramiento',
	'Toma': 'Toma de la EDS'
}

export const stepsDictionary = {
  1: "Revision Inicial de Solicitud",
	2: "Entrevista Telefónica",
	3: "Entrevista Psicológica Copec",
  4: "Test Psicológico",
  5: "Entrevista Jefe de Zona",
  6: "Entrevista Jefe Comercial",
  7: "Entrevista Gerente de Oficina",
  8: "Curso de Inducción/ Prueba Final para Certificación Concesionarios",
  9: "Revision documentos Aprobacion Legal",
  10: "Firma de Documentos",
  11: "Revisión nombramiento",
  12: "Firma Gerente Oficina",
  13: "Firma Gerente",
  14: "Práctica EDS",
  15: "Nombramiento",
  16: "Toma EDS"
}

export const userTypesDictionary = {
	"Gerente": "Gerente",
	"JefeZona": "Jefe de Zona",
	"JefeComercial": "Jefe Comercial"
}

