import React, { useState, useEffect } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Form from './components/Form'
import Processes from './components/Processes'
import Applicant from './components/Applicant'
import Report from './components/Report'
import ReportPostulantes from './components/ReportPostulantes'
import Users from './components/Users'
import Localidades from './components/Localidades'
import Rejection from './components/Rejection'
import Requirements from './components/Requirements'
import Measure from "react-measure";
import './App.scss';

function App() {
  const [ taskToken, setTaskToken ] = useState('')
  const [ token, setToken ] = useState(null)
  const [ user, setUser ] = useState()
  const [ newProcessName, setNewProcessName ] = useState('')

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false)
    window.parent.postMessage({ getToken: true }, "*")
    window.parent.postMessage({ getUser: true }, "*")
  }, [])

  function receiveMessage(event) {
    const message = event.data

    if (message.token) {
      setToken(message.token)
    }

    if(message.user){
      setUser(message.user)
    }

    /*

    if('token' in message){
      if (message.token) {
        setToken(message.token)
      }
      else{
        setTimeout(function(){ 
          window.parent.postMessage({ getToken: true }, "*")
        }, 1000)
      }
    }

    */

    /*

    else if ('user' in message) {
      if(message.user){
        setUser(message.user)
      }
      else{
        setTimeout(function(){ 
          window.parent.postMessage({ getUser: true }, "*")
        }, 1000)
      }
    }

    */
  }

  function resize(bounds) {
    window.parent.postMessage({ heightChange: bounds.height }, "*");
  }

  return (
    <div className="App">
      <Measure
        bounds
        onResize={(contentRect) => {
          resize(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Router history={history}>
              <Switch>
                <Route
                  path="/form"
                  render={(props) => (
                    <Form {...props} token={token} />
                  )}
                />
                <Route
                  path='/postulaciones'
                  render={(props) => (
                    <Processes
                      token={token} 
                      user={user}
                    />
                  )}
                />
                <Route
                  path='/rechazo/:id'
                  render={(props) => (
                    <Rejection props={props} token={token} />
                  )}
                />
                <Route
                  path='/postulante/:id'
                  render={(props) => (
                    <Applicant 
                      {...props} 
                      token={token}
                      user={user}
                    />
                  )}
                />
                <Route
                  path='/reporte'
                  render={(props) => (
                    <Report {...props} token={token} />
                  )}
                />
                <Route
                  path='/reporte-postulantes'
                  render={(props) => (
                    <ReportPostulantes
                      token={token}
                    />
                  )}
                />
                <Route
                  path='/users'
                  render={(props) => (
                    <Users {...props} token={token} />
                  )}
                />
                <Route
                  path='/localidades'
                  render={(props) => (
                    <Localidades {...props} token={token} />
                  )}
                />

                <Route path='/requerimientos' component={Requirements} />
                <Route path="/" exact />
              </Switch>
            </Router>
          </div>
        )}
      </Measure>
    </div>
  );
}

export default App;
