import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal"
import alerta_icon from "../assets/img/importante_icon.png"
import cross_icon from "../assets/img/cross-icon.svg"

import { 
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,
  scrollTo
} from "../utils.js";

const Localidades = ({token}) => {
  const [locations, setLocations] = useState([])
  const [editingLocation, setEditingLocation] = useState()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    if(token)
      getLocations()
  }, [token])

  function closeModal(){
    setEditingLocation()
    setModalIsOpen(false)
    hideGreyBackground()
  }

  function getLocations(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/reports/zones?residencies=1'
    fetch(url)
      .then(res => res.json())
      .then((result) => {
        setLocations(result)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function preEditarLocalidad(localidad){
    setEditingLocation(localidad)
    setModalIsOpen(true)
    showGreyBackground()
    scrollTo(0, 100)
  }

  async function editarLocalidad(){
    const localidad = JSON.parse(JSON.stringify(editingLocation))

    localidad.active = !localidad.active
    displayLoader()

    await fetch(process.env.REACT_APP_BASE_URL + "/workflow/reports/zones",
      { 
        method: 'PUT',    
        headers: { token: token },
        body: JSON.stringify(localidad)
      })
      .then(res => res.json())
      .then(async (result) => {
        if(result.Error){
          alert(result.Error)
        }
        else{
          getLocations()
        }
      },
      (error) => {
        alert(error);
      }
    )

    hideLoader()
    closeModal()
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Administración de Localidades</div>
        <hr className="title-hr" />
      </div>

      <div className="usuarios-section-title">Estado Localidades</div>

      <table className="copec-table-base localidades-table">
        <tr>
          <th className="first th-300">
            <div className="th-data">
              Localidad
            </div>
          </th>
          <th className="first th-120">
            <div className="th-data">
              Zona
            </div>
          </th>
          <th className="first th-120">
            <div className="th-data">
              Estado
            </div>
          </th>
          <th className="first th-150">
            <div className="th-data" />
          </th>
        </tr>
        <tr className="table-separator"><td colSpan="4"></td></tr>
        <tbody>
          {
            locations.map((location) => {
              return(
                <tr key={location.localidad}>
                  <td>{location.localidad}</td>
                  <td>{location.zona}</td>
                  <td className={location.active? 'location-active': 'location-not-active'}>{location.active? 'Activa': 'No activa'}</td>
                  <td className="action-column" onClick={() => preEditarLocalidad(location)}>
                    {location.active? 'Desactivar': 'Activar'}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <Modal
        open={modalIsOpen}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeModal}
      >
        <div className="modal-rechazo-container">
          <img className="big-icon" src={alerta_icon} />
          <div className="modal-title">
            Se va a {editingLocation && editingLocation.active? 'Desactivar': "Activar"} la localidad {editingLocation && editingLocation.localidad}
          </div>
          <div className="modal-info">
            ¿Está seguro que desea continuar?
          </div>
          <hr />
          <button className="save-button rechazo-button rechazo-button-hollow" onClick={closeModal}>Cancelar</button>
          <button className="save-button rechazo-button" onClick={editarLocalidad}>Aceptar</button>
        </div>
      </Modal>
    </div>
  )
};

export default Localidades;