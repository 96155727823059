export const CARTAS_COMPROMISO_STEP = 10
export const CARTON_FORM = 11
export const CARTON_SIGN_FIRST_STEP = 12
export const CARTON_SIGN_SECOND_STEP = 13
export const TOTAL_STEPS = 16

export const zones = [
  {value: 'centro', label: 'Centro'},
  {value: 'norte', label: 'Norte'},
  {value: 'sur', label: 'Sur'},
  {value: 'santiago', label: 'Santiago'}
]
