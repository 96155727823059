import React, {useState, useEffect} from "react";
import axios from 'axios'
import ExcelJS from 'exceljs'
import * as FileSaver from 'file-saver'
import {  
  displayLoader, 
  hideLoader } from "../utils.js"
import descargar from "../assets/img/descargar.svg"

const ExportExcel = ({ data, columnas }) => {
  const letters = ['A','B','C','D','E','F','G','H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF']

  async function handleDownload(){
    displayLoader()

    const descartados = []
    const pendientes = []

    const workbook = new ExcelJS.Workbook()

    workbook.creator = 'Tamus!'
    workbook.lastModifiedBy = 'Tamus!'
    workbook.created = new Date()
    workbook.modified = new Date()
 
    const DATA_ROWS = data.length
    const worksheet = workbook.addWorksheet("Hoja 1", {views: [{showGridLines: false}]})

    const worksheetColumns = []

    // Agregan keys de las columnas de datos
    columnas.forEach(function(col){
      worksheetColumns.push({key: col.key, width: col.width? col.width: 20, style: { alignment: { wrapText: true } }})
    })
    worksheet.columns = worksheetColumns

    // Agrega la primera fila con titulos de las columnas
    const firstRow = {}
    columnas.forEach(function(col, index){
      firstRow[col.key] = col.name
    })

    worksheet.addRow(firstRow)

    const row1 = worksheet.getRow(1)

    row1.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'15365c'}
    }

    row1.font = {
      color: {
        argb: 'FFFFFFFF',
        theme: 1
      }
    }
    
    // Se agregan los datos
    data.forEach((trx, key) => {
      const newRow = {}
      columnas.forEach((col, index) => {
        newRow[col.key] = trx[col.key]
        if(trx[col.key] === "Descartado" && index > 6){
          descartados.push({row: (key + 2), letter: index})
        }
        else if(trx[col.key] && trx[col.key].includes("Pendiente hace") && index > 6){
          pendientes.push({row: (key + 2), letter: index})
        }
      })
      worksheet.addRow(newRow)
    })

    descartados.forEach((d) => {
      worksheet.getCell(letters[d.letter]+d.row.toString()).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'f5cacc'}
      }
    })

    pendientes.forEach((p) => {
      worksheet.getCell(letters[p.letter]+p.row.toString()).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'ffff00'}
      }
    })

    const blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: blobType }); 
      FileSaver.saveAs(blob, "ReporteWorkflow");
    });

    hideLoader()
  }

  return (
    <a onClick={handleDownload}><img src={descargar} className="cursor-pointer" /></a>
  )
};

export default ExportExcel
