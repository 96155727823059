import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import ExportExcel from "./ExportExcel"

import { 
  displayLoader,
  hideLoader,
  parseDate,
  parseDateNoHour,
  getDaysSince,
  SingleOption,
  customStylesSelect,
  getDatesHoursDifference,
  pasos
} from "../utils.js"

import Select from 'react-select'
import '../styles/report.scss'

const TOTAL_STEPS = 16

const stepsTitles = [
  "Revisión Inicial de Solicitud",
  "Entrevista Telefónica",
  "Entrevista Psicológica Copec",
  "Test Psicológico",
  "Entrevista Jefe de Zona",
  "Entrevista Jefe Comercial",
  "Entrevista Gerente de Oficina",
  "Curso de Inducción/ Prueba Final para Certificación Concesionarios",
  "Revisión documentos Aprobación Legal",
  "Firma de Documentos",
  "Revisión nombramiento",
  "Firma Gerente Oficina",
  "Firma Gerente",
  "Práctica EDS",
  "Nombramiento",
  "Toma EDS"
]

const ReportPostulantes = ({token}) => {
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ zone, setZone ] = useState();
  const [ jefeZona, setJefeZona ] = useState()
  const [ jefesZona, setJefesZona ] = useState([{value: 'todos', label: 'Todos'}])
  const [ jefeComercial, setJefeComercial ] = useState()
  const [ jefesComerciales, setJefesComerciales ] = useState([{value: 'todos', label: 'Todos'}])
  const [ users, setUsers ] = useState([])
  const [ postulations, setPostulations ] = useState([])
  const [ allPostulations, setAllPostulations ] = useState([])
  const [ status, setStatus ] = useState()
  
  const zones = [
    {value: 'todas', label: 'Todas'},
    {value: 'centro', label: 'Centro'},
    {value: 'norte', label: 'Norte'},
    {value: 'sur', label: 'Sur'},
    {value: 'santiago', label: 'Santiago'}
  ]

  const allStatus = [
    {value: 'all', label: 'Todos'},
    {value: 'En Proceso', label: 'En Proceso'},
    {value: 'Descartado', label: 'Descartado'},
    {value: 'Terminado', label: 'Terminado'}
  ]

  const columnas = [
    { key: 'num', name: "n°", width: 5 },
    { key: 'startDateNoHour', name: "Fecha del ingreso de la postulacion- Paso 0" },
    { key: 'endDateNoHour', name: "Fecha de termino de la postulacion" },
    { key: 'postulationStatus', name: "Estado" },
    { key: 'referred', name: "Referido", width: 20 },
    { key: 'fullName', name: "Nombre Postulante" },
    { key: 'email', name: "Correo" },
    { key: 'phone', name: "Numero de telefono" },
    { key: 'jefeZona', name: "Jefe de Zona asignado" },
    { key: 'jefeComercial', name: "Jefe Comercial Asignado" },
    { key: 'location', name: "Localidad" }
  ]

  stepsTitles.forEach((title, stepNumber) => {
    const keyName = 'stepStatus' + (stepNumber + 1).toString()
    const stepName = "Paso " + (stepNumber + 1).toString() + ": " + title
    columnas.push({ key: keyName, name: stepName, width: 20})
  })

  useEffect(() => {
    if(token && users.length > 0)
      getPostulations()
  }, [token, startDate, endDate, zone, jefeZona, jefeComercial, users])

  useEffect(() => {
    filterStatus()
  }, [status, allPostulations])

  useEffect(() => {
    if(token)
      getUsers()
  }, [token])

  useEffect(() => {
    if(zone && zone.value !== 'todas' && users.length > 0){
      const jefesComercialesUsers = users.filter(u => u.zone === zone.value && u.type === 'JefeComercial')
      const newJefesComerciales = [{value: 'todos', label: 'Todos'}]

      jefesComercialesUsers.forEach(function(jefe){
        newJefesComerciales.push({
          value: jefe.id,
          label: jefe.name
        })
      })
      
      setJefesComerciales(newJefesComerciales)
      setJefeZona(null)
      setJefeComercial(null)
      setJefesZona([{value: 'todos', label: 'Todos'}])
    }
    else{
      setJefeZona()
      setJefeComercial()
      setJefesZona([{value: 'todos', label: 'Todos'}])
      setJefesComerciales([{value: 'todos', label: 'Todos'}])
    }
  }, [zone, users])

  useEffect(() => {
    if(jefeComercial && jefeComercial.value !== 'todos'){
      const jefesZonaUsers = users.filter(u => u.id_jefe_comercial === jefeComercial.value)
      const newJefesZona = [{value: 'todos', label: 'Todos'}]
      jefesZonaUsers.forEach(function(jefe){
        newJefesZona.push({
          value: jefe.id,
          label: jefe.name
        })
      })
      
      setJefesZona(newJefesZona)
      setJefeZona(null)    
    }
    else{
      setJefeZona(null)
      setJefesZona([{value: 'todos', label: 'Todos'}])
    }
  }, [jefeComercial])

  function filterStatus(){
    if(!status || status.value === "all"){
      setPostulations(allPostulations)
      return
    }

    const newPostulations = []
    const statusValue = status.value

    allPostulations.forEach(function(p){
      if(p.postulationStatus === statusValue)
        newPostulations.push(p)
    })

    setPostulations(newPostulations)
  }

  function getPostulations(){
    displayLoader()
    let url = process.env.REACT_APP_BASE_URL + '/workflow/processes?allProcesses=1'
    if(startDate){
      url += '&startDate=' + parseDate(startDate).substring(0, 10)
    }
    if(endDate)
      url += '&endDate=' + parseDate(endDate).substring(0, 10)

    if(jefeZona && jefeZona.value !== 'todos')
      url += '&jefeZona=' + jefeZona.value

    else if(jefeComercial && jefeComercial.value !== 'todos')
      url += '&jefeComercial=' + jefeComercial.value
    
    else if(zone && zone.value !== 'todas')
      url += '&zone=' + zone.value

    fetch(url, { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        hideLoader()
        parsePostulations(result)
      },
      (error) => {
        alert(error);
        hideLoader()
      }
    )
  }

  function parsePostulations(result){
    const parsed = []

    result.forEach(function(postulation, index){
      const tmp = {
        id: postulation.id,
        num: (index + 1).toString(),
        fullName: postulation.name + ' ' + postulation.lastName,
        email: postulation.email,
        location: postulation.location,
        phone: postulation.phone,
        jefeZona: postulation.jefeZona?.name,
        jefeComercial: getJefeComercial(postulation.jefeZona),
        startDate: parseDate(postulation.startDate),
        startDateNoHour: parseDateNoHour(postulation.startDate),
        endDate: parseDate(postulation.endDate),
        endDateNoHour: parseDateNoHour(postulation.endDate),
        totalTime: getTiempoTotal(postulation),
        isFinished: postulation.lastStepAccepted? 'Si': 'No',
        referred: postulation.referredName === ""? "No indica": postulation.referredName,
        postulationStatus: getPostulationStatus(postulation)
      }

      for(let step = 1; step <= TOTAL_STEPS ; step++){
        const paramName = 'timeInStep' + step.toString()
        tmp[paramName] = getTimeInStep(postulation, step)

        const excelParamName = 'stepStatus' + step.toString()
        tmp[excelParamName] = getStatusForExcel(postulation, step)
      }

      parsed.push(tmp)
    })

    setAllPostulations(parsed)
  }

  function getJefeComercial(postulationJefeZona){
    if(!postulationJefeZona)
      return ''

    const jc = users.find(u => u.id == postulationJefeZona.id_jefe_comercial)
    if(jc)
      return jc.name

    return ''
  }

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setUsers(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function getPostulationStatus(postulation){
    const postulationEndDate = postulation.endDate
    const lastStepAccepted = postulation.lastStepAccepted
    
    if(!postulationEndDate)
      return('En Proceso')
    else if(lastStepAccepted)
      return('Terminado')
    
    return("Descartado")
  }

  function getStatusForExcel(postulation, initialStep){
    const initialStepPropName = 'stepStartDateStep' + initialStep.toString()
    const finalStepPropName = 'stepStartDateStep' + (initialStep + 1).toString()
    const initialDate = postulation[initialStepPropName]
    const finalDate = postulation[finalStepPropName]
    const postulationEndDate = postulation.endDate
    const postulationStep = postulation.step

    if(!initialDate)
      return('')
    else if(finalDate)
      return("Realizado")
    else if(postulationEndDate > 0 && postulation.lastStepAccepted)
      return('Realizado')
    else if(postulationEndDate > 0)
      return('Descartado')

    return('Pendiente hace ' + getDaysSince(initialDate) + ' días')
  }

  function getTimeInStep(postulation, initialStep){
    const initialStepPropName = 'stepStartDateStep' + initialStep.toString()
    const finalStepPropName = 'stepStartDateStep' + (initialStep + 1).toString()

    const initialDate = postulation[initialStepPropName]
    const finalDate = postulation[finalStepPropName]
    const postulationEndDate = postulation.endDate
    const postulationStep = postulation.step

    let response = ''

    if(initialDate && finalDate)
      response = getDatesHoursDifference(initialDate, finalDate)
    else if(initialDate && postulationEndDate > 0 && postulationStep < TOTAL_STEPS)
      response = 'Rechazado el ' + parseDate(postulationEndDate)
    else if(initialDate)
      response = 'En proceso desde ' + parseDate(initialDate)

    return response
  }

  function getTiemposPorPasos(postulation){
    return [...Array(17)].map(function(a, key){
      const step = key + 1
      const paramName = 'timeInStep' + step.toString()
      return(
        <td>
          {postulation[paramName]}
        </td>
      )
    })
  }

  function getHeaders(){
    return columnas.map(function(col){
      return(
        <th className="th-170">
          <div className="th-data triple-line">
            <div className="th-text">{col.name}</div>
          </div>
        </th>
      )
    })
  }

  function getTiempoTotal(postulation){
    const startDate = postulation.startDate
    let endDate = new Date()
    if(postulation.endDate)
      endDate = postulation.endDate

    return getDatesHoursDifference(startDate, endDate)
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Reporte Postulantes</div>
        <hr className="title-hr" />
      </div>
      <div className="filters-container">
        <div className="filter-container">
          <div className="filter-title">Estado</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={status}
              onChange={setStatus}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allStatus}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>
        <div className="filter-container">
          <div className="filter-title">Oficina</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={zone}
              onChange={setZone}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={zones}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Jefe Comercial</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={jefeComercial}
              onChange={setJefeComercial}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={jefesComerciales}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Jefe de Zona</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={jefeZona}
              onChange={setJefeZona}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={jefesZona}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Fecha Inicio</div>
          <div className="filter-input report-postulantes-datepicker">
            <DatePicker
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              placeholderText="Seleccionar"
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Fecha Fin</div>
          <div className="filter-input report-postulantes-datepicker">
            <DatePicker
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              placeholderText="Seleccionar"
            />
          </div>
        </div>

        <div className="tables-container">
          <div className="table-left">
            <div className="table-title table-title-report-postulantes">
              <div className="title-text">
                POSTULANTES
              </div>
              <div className="export-excel">                
                <ExportExcel 
                  data={postulations}
                  columnas={columnas}
                />
              </div>
            </div>
            <table className="copec-table-base processes-table report-postulantes-table">
              <tr>
                { getHeaders() }
              </tr>
              <tr className="table-separator"><td colSpan="5"></td></tr>
              <tbody>
                {
                  postulations.map((process) => {
                    return(
                      <tr key={process.id}>

                        {columnas.map((col) => {
                          return(
                            <td>
                              {process[col.key]}
                            </td>
                          )
                        } )}
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ReportPostulantes;