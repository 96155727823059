import React, { useState, useEffect } from "react"
import { displayLoader, hideLoader, parseDate, months } from "../utils.js"
import DatePicker from "react-datepicker"
import { dictionary } from '../dictionary.js'
import { CARTON_FORM } from '../constants.js'
import '../styles/carton.scss'

const Carton = ({applicant, tableCombustiblesInputs, tableSociosInputs, tableSociosChange, textInputs, setTextInputsChange}) => {
  //const isEditing = (parseInt(process.env.REACT_APP_CARTON_SIGN_FIRST_STEP) > applicant.step)? true: false
  const isEditing = parseInt(CARTON_FORM) === applicant.step

  function getAvg(type){
    if(!type || !tableCombustiblesInputs['Gas93'])
      return 0

    if(type === 'gas'){
      let total = 0

      Object.keys(tableCombustiblesInputs['Gas93']).map((key) => {
        total += parseInt(tableCombustiblesInputs['Gas93'][key])
      })

      Object.keys(tableCombustiblesInputs['Gas95']).map((key) => {
        total += parseInt(tableCombustiblesInputs['Gas95'][key])
      })

      Object.keys(tableCombustiblesInputs['Gas97']).map((key) => {
        total += parseInt(tableCombustiblesInputs['Gas97'][key])
      })

      total = total / 6
      return parseInt(total)
    }
    else{
      let total = 0

      Object.keys(tableCombustiblesInputs[type]).map((key) => {
        total += parseInt(tableCombustiblesInputs[type][key])
      })

      total = total / 6
      return parseInt(total)
    }
  }

  function getMonthStr(date, monthAdd){
    if(!date)
      return ''

    const dateArr = date.split('/')
    let month = parseInt(dateArr[1]) - 1 + monthAdd
    let year = parseInt(dateArr[2])
    if(month > 11){
      month = month%12
      year++
    }

    return `${months[month]} ${year}`
  }

  function customDateSaver(date, key){
    const month = date.getMonth() + 1
    const dateText = '01/' + month + '/' + date.getFullYear()
    setTextInputsChange(dateText, key, 'text')
  }

  function getSelectedDate(key){
    if(!textInputs[key])
      return null

    const textInputsArr = textInputs[key].split('/')
    return new Date(textInputsArr[2], textInputsArr[1] - 1, textInputsArr[0])
  }

  return (
    <div className='carton'>
      <div className="section">
        <div className="section-title"><span>DATOS EDS</span></div>
        <div className="section-data section-data-190">
          <div className='title'>NUMERO EDS</div>
          <div className='data'>{ applicant.Eds }</div>
        </div>

        <div className="section-data section-data-190">
          <div className='title'>CIUDAD</div>
          <div className='data'>{ applicant.location }</div>
        </div>

        <div className="section-data section-data-416 section-data-last">
          <div className='title'>DIRECCION</div>
          <div className='data'>{ applicant.DireccionEds }</div>
        </div>
      </div>

      <div className="section">
        <div className="section-title"><span>DATOS CONCESIONARIO</span></div>
        <div className="section-data section-data-260">
          <div className='title'>NOMBRE</div>
          <div className='data'>{ applicant.name }&nbsp;{ applicant.lastName }</div>
        </div>

        <div className="section-data section-data-170">
          <div className='title'>RUT</div>
          <div className='data'>{ applicant.rut }</div>
        </div>

        <div className="section-data section-data-170">
          <div className='title'>PROFESION</div>
          <div className='data'>{ applicant.profession }</div>
        </div>

        <div className="section-data section-data-170 section-data-last">
          <div className='title'>NACIONALIDAD</div>
          {!isEditing &&
            <div className='data'>
              {applicant.NacionalidadConcesionario}
            </div>
          }
          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['NacionalidadConcesionario']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'NacionalidadConcesionario', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-462">
          <div className='title'>NOMBRE CONCESIONARIO SALIENTE</div>
          {!isEditing &&
            <div className='data'>{ applicant.ConcesionarioSaliente }</div>
          }
          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['ConcesionarioSaliente']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'ConcesionarioSaliente', 'text')} 
              />
            </div>
          }
        </div>
      </div>

      <div className="section">
        <div className="section-title"><span>NOTAS</span></div>
        <div className="section-data section-data-96">
          <div className='title'>JEFE ZONA</div>
          <div className='data'>{ applicant.NotaJefeZona }</div>
        </div>
        <div className="section-data section-data-96">
          <div className='title'>PSICOLOGICO</div>
          <div className='data'>{ applicant.NotaPsicologico }</div>
        </div>

        <div className="section-data section-data-96">
          <div className='title'>JEFE COMERCIAL</div>
          <div className='data'>{ applicant.NotaJefeComercial }</div>
        </div>

        <div className="section-data section-data-96">
          <div className='title'>GERENTE OFICINA</div>
          <div className='data'>{ applicant.NotaGerenteOficina }</div>
        </div>

        <div className="section-data section-data-96">
          <div className='title'>CURSO INDUCCION</div>
          <div className='data'>{ applicant.NotaCursoInduccion }</div>
        </div>

        <div className="section-data section-data-96">
          <div className='title'>PRACTICA EDS</div>
          <div className='data'>{ applicant.NotaPractica }</div>
        </div>

        <div className="section-data section-data-96 section-data-last">
          <div className='title'>CERTIFICACION</div>
          <div className='data'>{ applicant.NotaPrueba }</div>
        </div>
      </div>

      <div className="section">
        <div className="section-title"><span>DATOS SOCIEDAD</span></div>

        <div className="section-data section-data-192">
          <div className='title'>RUT SOCIEDAD</div>
          {!isEditing &&
            <div className='data'>{ applicant.RutSociedad }</div>
          }
          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['RutSociedad']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'RutSociedad', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-640 section-data-last">
          <div className='title'>RAZON SOCIAL</div>
          <div className='data'>{ applicant.RazonSocial }</div>
        </div>
          
        <table className="tabla-copec-1">
          <thead>
            <tr>
              <th>Nombre socios</th>
              <th>RUT</th>
              <th>Profesión</th>
              <th>% Sociedad</th>
            </tr>
          </thead>
          <tr className="tabla-copec-1-separator"><td colSpan="4" /></tr>
          {!isEditing &&
            <tbody>
              { tableSociosInputs && tableSociosInputs.map((socio, key) => {
                return (
                  <>
                    <tr className="tabla-copec-1-tbody-tr">
                      <td><div className="tabla-copec-1-input">{ socio.nombre }</div></td>
                      <td><div className="tabla-copec-1-input">{ socio.rut }</div></td>
                      <td><div className="tabla-copec-1-input">{ socio.profesion }</div></td>
                      <td><div className="tabla-copec-1-input">{ socio.porcentaje }</div></td>
                    </tr>
                    {
                      tableSociosInputs[key+1] &&
                      <tr className="tabla-copec-1-tbody-separator"><td colSpan="4"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
                    }
                  </>
                )
              })}
            </tbody>
          }
          {isEditing &&
            <tbody>
              {
                tableSociosInputs && tableSociosInputs.map((obj, key) => {
                  return(
                    <>
                      <tr className="table-copec-1-editing">
                        <td>
                          <div className="tabla-copec-1-input-text">
                            <input className="tabla-copec-1-input-text-socio" type='text' value={ obj.nombre } onChange={(e) => tableSociosChange(key, 'nombre', e.target.value)} />
                          </div>
                        </td>
                        <td>
                          <div className="tabla-copec-1-input-text">
                            <input className="tabla-copec-1-input-text-rut" type='text' value={ obj.rut } onChange={(e) => tableSociosChange(key, 'rut', e.target.value)} />
                          </div>
                        </td>
                        <td>
                          <div className="tabla-copec-1-input-text">
                            <input className="tabla-copec-1-input-text-profesion" type='text' value={ obj.profesion } onChange={(e) => tableSociosChange(key, 'profesion', e.target.value)} />
                          </div>
                        </td>
                        <td>
                          <div className="tabla-copec-1-input-text">
                            <input className="tabla-copec-1-input-text-sociedad" type='text' value={ obj.porcentaje } onChange={(e) => tableSociosChange(key, 'porcentaje', e.target.value)} />
                          </div>
                        </td>
                      </tr>
                      {
                        tableSociosInputs[key+1] &&
                        <tr className="tabla-copec-1-tbody-separator"><td colSpan="4"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
                      }
                    </>
                  )
              })}        
            </tbody>
          }
        </table>
      </div>

      <div className="section">
        <div className="section-title"><span>VENTAS</span></div>

        <div className="section-data no-margin">
          <div className='title'>META DE VENTAS</div>
        </div>

        <table className="tabla-copec-1">
          <thead>
            <tr>
              <th>Producto</th>
              <th colSpan="2">Promedio ventas mensuales ultimos 3 meses</th>
              <th colSpan="2">Promedio meta ventas mensuales primeros 6 meses</th>
            </tr>
          </thead>
          <tr className="tabla-copec-1-separator"><td colSpan="3" /></tr>
          <tbody>
            <tr className="tabla-copec-1-tbody-tr promedio-ventas-table-subtitles">
              <td></td>
              <td>
                <div className="promedio-ventas-table-subtitles-inner">
                  <div className="promedio-ventas-table-title">MES 1</div>
                  {!isEditing &&
                    <div className="tabla-copec-1-input">{getMonthStr(applicant.PromedioVentasMesDesde, 0)}</div>
                  }
                  {isEditing &&
                    <DatePicker
                      onChange={(date) => customDateSaver(date, 'PromedioVentasMesDesde')}
                      dateFormat="MM/yyyy"
                      selected={getSelectedDate('PromedioVentasMesDesde')}
                      showMonthYearPicker
                    />
                  }

                </div>
              </td>
              <td>
                <div className="promedio-ventas-table-subtitles-inner">
                  <div className="promedio-ventas-table-title">MES 3</div>
                  {!isEditing &&
                    <div className="tabla-copec-1-input">{getMonthStr(applicant.PromedioVentasMesDesde, 2)}</div>
                  }
                  {isEditing &&
                    <div className="tabla-copec-1-input">{getMonthStr(textInputs['PromedioVentasMesDesde'], 2)}</div>
                  }
                </div>
              </td>
              <td>
                <div className="promedio-ventas-table-subtitles-inner">
                  <div className="promedio-ventas-table-title">MES 1</div>
                  <div className="tabla-copec-1-input">{getMonthStr(applicant.MesInicioOperacion, 0)}</div>
                </div>
              </td>
              <td>
                <div className="promedio-ventas-table-subtitles-inner">
                  <div className="promedio-ventas-table-title">MES 6</div>
                  <div className="tabla-copec-1-input">{getMonthStr(applicant.MesInicioOperacion, 5)}</div>
                </div>
              </td>
            </tr>
            <tr className="tabla-copec-1-tbody-separator"><td colSpan="5"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
            <tr>
              <td><div className="tabla-copec-1-row-title">Gasolinas (m3)</div></td>
              <td colSpan="2">
                {!isEditing &&
                  <div className="tabla-copec-1-input">{ applicant.PromedioVentasGasolina }</div>
                }
                {isEditing &&
                  <div className="tabla-copec-1-input-text">
                    <input 
                      className="tabla-copec-1-input-text-gasolinas"
                      type='text' 
                      value={textInputs['PromedioVentasGasolina']} 
                      onChange={(e) => setTextInputsChange(e.target.value, 'PromedioVentasGasolina', 'text')} 
                    />
                  </div>
                }
              </td>
              <td colSpan="2"><div className="tabla-copec-1-input">{ getAvg('gas') }</div></td>
            </tr>
            <tr className="tabla-copec-1-tbody-separator"><td colSpan="5"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
            <tr>
              <td><div className="tabla-copec-1-row-title">Kerosene (m3)</div></td>
              <td colSpan="2">
                {!isEditing &&
                  <div className="tabla-copec-1-input">{ applicant.PromedioVentasKerosene }</div>
                }
                {isEditing &&
                  <div className="tabla-copec-1-input-text">
                    <input 
                      className="tabla-copec-1-input-text-gasolinas"
                      type='text' 
                      value={textInputs['PromedioVentasKerosene']} 
                      onChange={(e) => setTextInputsChange(e.target.value, 'PromedioVentasKerosene', 'text')} 
                    />
                  </div>
                }
              </td>
              <td colSpan="2"><div className="tabla-copec-1-input">{ getAvg('Kerosene') }</div></td>
            </tr>
            <tr className="tabla-copec-1-tbody-separator"><td colSpan="5"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
            <tr>
              <td><div className="tabla-copec-1-row-title">P. Diesel (m3)</div></td>
              <td colSpan="2">
                {!isEditing &&
                  <div className="tabla-copec-1-input">{ applicant.PromedioVentasDiesel }</div>
                }
                {isEditing &&
                  <div className="tabla-copec-1-input-text">
                    <input 
                      className="tabla-copec-1-input-text-gasolinas"
                      type='text' 
                      value={textInputs['PromedioVentasDiesel']} 
                      onChange={(e) => setTextInputsChange(e.target.value, 'PromedioVentasDiesel', 'text')} 
                    />
                  </div>
                }
              </td>
              <td colSpan="2"><div className="tabla-copec-1-input">{ getAvg('Diesel') }</div></td>
            </tr>
            <tr className="tabla-copec-1-tbody-separator"><td colSpan="5"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
            <tr>
              <td><div className="tabla-copec-1-row-title">Lubricantes (Lts.)</div></td>
              <td colSpan="2">
                {!isEditing &&
                  <div className="tabla-copec-1-input">{ applicant.PromedioVentasLubricantes }</div>
                }
                {isEditing &&
                  <div className="tabla-copec-1-input-text">
                    <input 
                      className="tabla-copec-1-input-text-gasolinas"
                      type='text' 
                      value={textInputs['PromedioVentasLubricantes']} 
                      onChange={(e) => setTextInputsChange(e.target.value, 'PromedioVentasLubricantes', 'text')} 
                    />
                  </div>
                }
              </td>
              <td colSpan="2"><div className="tabla-copec-1-input">{ applicant.PromedioMensualLubricantes }</div></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="section">
        <div className="section-title"><span>APORTES CONCESIONARIO</span></div>
        <div className="section-data section-data-190">
          <div className='title'>CAPITAL EXPLOTACIÓN (U.F.)</div>
          {!isEditing &&
            <div className='data'>
              {applicant.CapitalExplotacion}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['CapitalExplotacion']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'CapitalExplotacion', 'text')} 
              />
            </div>
          }
        </div>
        <div className="section-data section-data-190">
          <div className='title'>EQUIVALENTE EN DÍAS DE VENTA</div>
          {!isEditing &&
            <div className='data'>
              {applicant.EquivalenteEnDiasVenta}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['EquivalenteEnDiasVenta']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'EquivalenteEnDiasVenta', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-190">
          <div className='title'>CAPITAL PARA INVERSIÓN ($)</div>
          {!isEditing &&
            <div className='data'>
              {applicant.CapitalInversion}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['CapitalInversion']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'CapitalInversion', 'text')} 
              />
            </div>
          }

        </div>
      
        <div className="carton-section-subtitle">Garantia - Hipoteca</div>
        <div className="section-data section-data-192 section-data-last cantidad-hipotecas-form">
          <div className='title'>CANTIDAD DE HIPOTECAS</div>
          {!isEditing &&
            <div className='data'>
              {applicant.NumHipotecas}
            </div>
          }

          {isEditing &&
            <>
              <div className="cantidad-hipotecas-form-option">
                <input type="radio" id="1" name="cant-hipotecas" value="1" checked={textInputs['NumHipotecas'] === '1'} onChange={() => setTextInputsChange('1', 'NumHipotecas', 'text')} />1 Hipoteca
              </div>
              <div className="cantidad-hipotecas-form-option">
                <input type="radio" id="2" name="cant-hipotecas" value="2" checked={textInputs['NumHipotecas'] === '2'} onChange={() => setTextInputsChange('2', 'NumHipotecas', 'text')} />2 Hipotecas
              </div>
            </>
          }
        </div>

        <div className="section-data section-data-full">
          <div className='title'>DESCRIPCIÓN DEL BIEN RAIZ</div>
          {!isEditing &&
            <div className='data'>
              {applicant.BienRaiz}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['BienRaiz']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'BienRaiz', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-640">
          <div className='title'>DIRECCIÓN</div>
          {!isEditing &&
            <div className='data'>
              {applicant.DireccionBienRaiz}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['DireccionBienRaiz']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'DireccionBienRaiz', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-192 section-data-last">
          <div className='title'>CIUDAD</div>
          {!isEditing &&
            <div className='data'>
              {applicant.CiudadBienRaiz}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['CiudadBienRaiz']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'CiudadBienRaiz', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-192">
          <div className='title'>VALOR COMERCIAL (U.F.)</div>
          {!isEditing &&
            <div className='data'>
              {applicant.ValorBienRaiz}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['ValorBienRaiz']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'ValorBienRaiz', 'text')} 
              />
            </div>
          }
        </div>

        <div className="section-data section-data-192">
          <div className='title'>VALOR LIQUIDACIÓN (U.F.)</div>
          {!isEditing &&
            <div className='data'>
              {applicant.ValorLiquidacionBienRaiz}
            </div>
          }

          {isEditing &&
            <div className='data-input'>
              <input 
                type='text' 
                value={textInputs['ValorLiquidacionBienRaiz']} 
                onChange={(e) => setTextInputsChange(e.target.value, 'ValorLiquidacionBienRaiz', 'text')} 
              />
            </div>
          }
        </div>

        { (applicant.NumHipotecas === '2' || (isEditing && textInputs['NumHipotecas'] === '2')) &&
          <>
            <div className="carton-section-subtitle">Otras Garantías</div>

            <div className="section-data section-data-640">
              <div className='title'>DIRECCIÓN</div>
              {!isEditing &&
                <div className='data'>
                  {applicant.DireccionOtroBienRaiz}
                </div>
              }

              {isEditing &&
                <div className='data-input'>
                  <input 
                    type='text' 
                    value={textInputs['DireccionOtroBienRaiz']} 
                    onChange={(e) => setTextInputsChange(e.target.value, 'DireccionOtroBienRaiz', 'text')} 
                  />
                </div>
              }
            </div>

            <div className="section-data section-data-192 section-data-last">
              <div className='title'>CIUDAD</div>
              {!isEditing &&
                <div className='data'>
                  {applicant.CiudadOtroBienRaiz}
                </div>
              }

              {isEditing &&
                <div className='data-input'>
                  <input 
                    type='text' 
                    value={textInputs['CiudadOtroBienRaiz']} 
                    onChange={(e) => setTextInputsChange(e.target.value, 'CiudadOtroBienRaiz', 'text')} 
                  />
                </div>
              }
            </div>

            <div className="section-data section-data-192">
              <div className='title'>VALOR COMERCIAL (U.F.)</div>
              {!isEditing &&
                <div className='data'>
                  {applicant.ValorOtroBienRaiz}
                </div>
              }

              {isEditing &&
                <div className='data-input'>
                  <input 
                    type='text' 
                    value={textInputs['ValorOtroBienRaiz']} 
                    onChange={(e) => setTextInputsChange(e.target.value, 'ValorOtroBienRaiz', 'text')} 
                  />
                </div>
              }
            </div>

            <div className="section-data section-data-192 section-data-last">
              <div className='title'>VALOR LIQUIDACIÓN (U.F.)</div>
              {!isEditing &&
                <div className='data'>
                  {applicant.ValorLiquidacionOtroBienRaiz}
                </div>
              }

              {isEditing &&
                <div className='data-input'>
                  <input 
                    type='text' 
                    value={textInputs['ValorLiquidacionOtroBienRaiz']} 
                    onChange={(e) => setTextInputsChange(e.target.value, 'ValorLiquidacionOtroBienRaiz', 'text')} 
                  />
                </div>
              }
            </div>
          </>
        }
      </div>
      
    </div>
  )
};

export default Carton;
